import React, { useState } from "react";
import axios from "axios";

const AddForm = ({ onSave, onCancel, setShouldFetch }) => {
  const initialFormData = {
    id: null,
    name: "",
    sets: "",
    muscle: "",
    description: "",
    images: [],
    drive_video_link: "",
    // videos: [],
  };

  const [formData, setFormData] = useState(initialFormData);
  const [previewImages, setPreviewImages] = useState([]);
  const [loading, setLoading] = useState(false);
  // const [previewVideos, setPreviewVideos] = useState([]);

  const token = localStorage.getItem("authenticatedToken");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevValues) => ({ ...prevValues, [name]: value }));
  };

  const handleFileChange = (e, type) => {
    const files = Array.from(e.target.files);

    // Update formValues with new files
    setFormData((prevValues) => ({
      ...prevValues,
      [type]: files,
    }));

    // Generate previews for newly added files
    const previews = files.map((file) => ({
      url: URL.createObjectURL(file),
      preview: false,
    }));

    if (type === "images") {
      setPreviewImages(previews);
    }
    // else if (type === "videos") {
    //   setPreviewVideos(previews);
    // }
  };

  // const handleFileChange = (e) => {
  //   const files = Array.from(e.target.files);
  //   setFormData((prevValues) => ({
  //     ...prevValues,
  //     images: [...prevValues.images, ...files],
  //   }));

  //   // Generate image previews for newly added images
  //   const previews = files.map((file) => ({
  //     url: URL.createObjectURL(file),
  //     preview: false, // Indicates new image
  //   }));
  //   setPreviewImages((prevPreviews) => [...prevPreviews, ...previews]);
  // };

  // const handleVideosFileChange = (e) => {
  //   const files = Array.from(e.target.files);
  //   setFormData((prevValues) => ({
  //     ...prevValues,
  //     videos: [...prevValues.videos, ...files],
  //   }));

  //   // Generate video previews for newly added videos
  //   const previews = files.map((file) => ({
  //     url: URL.createObjectURL(file),
  //     preview: false, // Indicates new video
  //   }));
  //   setPreviewVideos((prevPreviews) => [...prevPreviews, ...previews]);
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formDataToSend = new FormData();
    formDataToSend.append("name", formData.name);
    formDataToSend.append("sets", formData.sets);
    formDataToSend.append("muscle", formData.muscle);
    formDataToSend.append("description", formData.description);
    formDataToSend.append("drive_video_link", formData.drive_video_link);

    formData.images.forEach((file, index) => {
      if (file instanceof File) {
        formDataToSend.append(`images[${index}]`, file);
      }
    });

    // formData.videos.forEach((file, index) => {
    //   if (file instanceof File) {
    //     formDataToSend.append(`videos[${index}]`, file);
    //   }
    // });

    try {
      setLoading(true);
      const response = await axios.post(
        "https://api.drmicheladib.com/api/v1/admin/exercises",
        formDataToSend,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      onSave(response.data);
      // Reset form and previews after successful save
      setFormData(initialFormData);
      setPreviewImages([]);
      setShouldFetch(true);
      // setPreviewVideos([]);
    } catch (error) {
      console.error("Error adding exercise:", error);
    }
    setLoading(false);
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div className="bg-[#252525] pt-10 pb-24 px-16 rounded-2xl shadow-lg shadow-slate-600 max-w-3xl max-h-[95vh] overflow-auto w-full mt-10">
        <form className="mt-5 space-y-6 " onSubmit={handleSubmit}>
          <div className="rounded-md shadow-sm space-y-4">
            <div>
              <h2 className="text-white text-start text-2xl mb-2 font-bold">
                Name
              </h2>
              <input
                id="name"
                name="name"
                type="text"
                required
                className="w-full px-4 py-2 bg-[#202023] border-gray-500 rounded-lg text-white transition duration-300 ease-in-out transform border-4 border-transparent focus:outline-none focus:border-orange-500"
                placeholder="Name"
                value={formData.name}
                onChange={handleInputChange}
              />
            </div>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <h2 className="text-white text-start text-2xl mb-2 font-bold">
                  Sets
                </h2>
                <input
                  id="sets"
                  name="sets"
                  type="number"
                  required
                  className="w-full px-4 py-2 bg-[#202023] border-gray-500 rounded-lg text-white transition duration-300 ease-in-out transform border-4 border-transparent focus:outline-none focus:border-orange-500"
                  placeholder="Sets"
                  value={formData.sets}
                  onChange={handleInputChange}
                />
              </div>

              <div>
                <h2 className="text-white text-start text-2xl mb-2 font-bold">
                  Exercise Type
                </h2>
                <input
                  id="muscle"
                  name="muscle"
                  type="text"
                  required
                  className="w-full px-4 py-2 bg-[#202023] border-gray-500 rounded-lg text-white transition duration-300 ease-in-out transform border-4 border-transparent focus:outline-none focus:border-orange-500"
                  placeholder="Exercise Type"
                  value={formData.muscle}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="mb-6">
              <h2 className="text-white text-start text-2xl mb-2 font-bold">
                Description
              </h2>
              <textarea
                id="description"
                name="description"
                required
                rows={6}
                placeholder="Type your description"
                className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                value={formData.description}
                onChange={handleInputChange}
              />
            </div>
            <div className="mb-6">
              <h2 className="text-white text-start text-2xl mb-2 font-bold">
                Images
              </h2>
              <input
                id="images"
                name="images"
                type="file"
                multiple
                accept="image/*"
                className="w-full px-4 py-2 bg-[#202023] border-gray-500 rounded-lg text-white transition duration-300 ease-in-out transform border-4 border-transparent focus:outline-none focus:border-orange-500"
                onChange={(e) => handleFileChange(e, "images")}
              />
              <div className="mt-4 grid grid-cols-3 gap-2">
                {previewImages.map((image, index) => (
                  <div key={index} className="relative">
                    <img
                      src={image.url}
                      alt={`Preview ${index}`}
                      className="w-24 h-24 object-cover rounded"
                    />
                  </div>
                ))}
              </div>
            </div>
            <div>
              <h2 className="text-white text-start text-2xl mb-2 font-bold">
                Drive Video Link
              </h2>
              <input
                id="drive_video_link"
                name="drive_video_link"
                type="text"
                required
                className="w-full px-4 py-2 bg-[#202023] border-gray-500 rounded-lg text-white transition duration-300 ease-in-out transform border-4 border-transparent focus:outline-none focus:border-orange-500"
                placeholder="Drive Video Link"
                value={formData.drive_video_link}
                onChange={handleInputChange}
              />
            </div>
            {/* <div className="mb-6">
              <h2 className="text-white text-start text-2xl mb-2 font-bold">
                Videos
              </h2>
              <input
                id="videos"
                name="videos"
                type="file"
                multiple
                accept="video/*"
                className="w-full px-4 py-2 bg-[#202023] border-gray-500 rounded-lg text-white transition duration-300 ease-in-out transform border-4 border-transparent focus:outline-none focus:border-orange-500"
                onChange={(e) => handleFileChange(e, "videos")}
              />
              <div className="mt-4 grid grid-cols-3 gap-2">
                {previewVideos.map((video, index) => (
                  <div key={index} className="relative">
                    <video
                      src={video.url}
                      alt={`Preview ${index}`}
                      className="w-24 h-24 object-cover rounded"
                      controls
                    />
                  </div>
                ))}
              </div>
            </div> */}
          </div>
          <div className="flex justify-end space-x-4">
            <button
              type="button"
              className="bg-gray-500 text-white py-2 px-4 rounded-lg shadow hover:bg-gray-600 focus:outline-none"
              onClick={onCancel}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="bg-green-500 text-white py-2 px-4 rounded-lg shadow hover:bg-green-600 focus:outline-none "
              disabled={loading}
            >
              {loading ? "Loading..." : "Save"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddForm;
