import React from "react";

import HeroSection from "../components/Landing/HeroSection";
import StatsSection from "../components/Landing/StatsSection";
import CircleSection from "../components/Landing/CircleSection";
import FeaturesSection from "../components/Landing/FeaturesSection";
import TestimonialsSection from "../components/Landing/TestimonialsSection ";
import LevelUpSection from "../components/Landing/LevelUpSection";
import ExpertSection from "../components/Landing/ExpertSection";
import PricingSection from "../components/Landing/PricingSection";
import FAQSection from "../components/Landing/FAQSection";
import Footer from "../components/Landing/Footer";

const Landing = () => {
  return (
    <div className="font-sans bg-[#151515] overflow-hidden">
      {/* Hero Section */}
      <HeroSection />

      {/* Stats Section */}
      <StatsSection />

      {/* Circle Section */}
      <CircleSection />

      {/* Features Section */}
      <FeaturesSection />

      {/* Testimonials Section */}
      <TestimonialsSection />

      {/* Level Up Your Fitness */}
      <LevelUpSection />

      {/* Expert Section */}
      <ExpertSection />

      {/* Pricing Section */}
      <PricingSection />

      {/* FAQ Section */}
      <FAQSection />

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default Landing;
