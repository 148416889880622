import React from "react";
import { useTranslation } from "react-i18next";

const PricingCard = ({
  name,
  price,
  old_price,
  features_detail,
  plan_type,
  description,
  invoice_period,
}) => {
  const perDay = parseFloat((price / (invoice_period * 30)).toFixed(2));
  const { t } = useTranslation();

  return (
    <div className="relative  p-6 bg-[#1C1B1E] rounded-lg shadow-lg transition duration-300 ease-in-out transform hover:border-orange-500 border-4 border-transparent  w-96">
      <div className="absolute w-[calc(100%+8px)] rounded-t-lg h-14 -top-1 -left-1 bg-orange-500 text-white text-2xl font-bold pt-2">
        {plan_type === "Most Popular" ? t("mostPopular") : t("standard")}
      </div>

      <div className="text-center pt-3">
        <h3 className="text-white text-3xl font-semibold mt-12">{name}</h3>
        <h3 className="text-white text-base mt-2">{description}</h3>

        <p className="text-orange-400 text-xl mt-4">{features_detail}</p>

        <div className="text-white text-4xl mt-4 relative line-through opacity-50">
          <span className="absolute text-[24px] bottom-2 -ml-4">$</span>
          {old_price}
        </div>

        <div className="text-white text-[55px] font-bold pb-2 relative">
          <span className="absolute text-[30px] -ml-5">$</span>
          {price}
        </div>
        <p className="text-orange-400 text-xl">
          $ {perDay} {t("perDay")}
        </p>
        <button className="bg-orange-500 font-semibold text-white w-3/4  text-[20px] py-3 mt-10 mb-2 rounded-3xl">
          {t("subscribe")}
        </button>
      </div>
    </div>
  );
};

export default PricingCard;
