import React from "react";
import { useTranslation } from "react-i18next";
import image1 from "../../assets/images/Frame 1.png";
import image2 from "../../assets/images/Frame 2.png";
import image3 from "../../assets/images/Frame 3.png";

import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
const FeaturesSection = () => {
  const { t } = useTranslation();

  const controls = useAnimation();
  const [ref, inView] = useInView({ threshold: 0.5 });
  const controls2 = useAnimation();
  const [ref2, inView2] = useInView({ threshold: 0.5 });

  React.useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  React.useEffect(() => {
    if (inView2) {
      controls2.start("visible");
    }
  }, [controls2, inView2]);
  return (
    <section className="bg-[#151515] text-white py-20">
      <h2 className="text-[#FF6400] text-3xl md:text-5xl text-center font-bold mb-32">
        {t("whyChooseOrangeFit")}
      </h2>
      {/* Feature 1 */}
      <div className="flex flex-col-reverse text-center md:text-start justify-evenly md:flex-row items-center space-y-4 md:space-y-0 animate-fadeIn">
        <div className="relative w-4/5 md:w-3/5 md:pl-40">
          <img
            src={image1}
            alt={t("feature1Title")}
            className="rounded-md shadow-lg "
          />
        </div>
        <div className="relative md:w-2/4 pl-0 md:pl-16 bottom-10 ">
          <h2 className="text-5xl md:text-7xl font-bold text-[#FF6400] mb-6">
            {t("feature1Title")}
          </h2>
          <h3 className="text-3xl md:text-5xl font-semibold mb-8">
            <p>{t("feature1Subtitle1")}</p>
            <p>{t("feature1Subtitle2")}</p>
          </h3>
          <div className="text-xl md:text-2xl">
            <p className="mb-1">{t("feature1Text1")}</p>
            <p className="mb-1">{t("feature1Text2")}</p>
            <p className="mb-1">{t("feature1Text3")}</p>
            <p>{t("feature1Text4")}</p>
          </div>
        </div>
      </div>
      {/* Feature 2 */}
      <div className="flex flex-col justify-evenly  md:flex-row items-center space-y-44 md:space-y-0  animate-fadeIn">
        <div className="relative md:w-2/4 pr-0 md:pr-16 -bottom-24 md:bottom-28 text-center md:text-start">
          <h2 className="text-5xl md:text-7xl md:text-right font-bold text-[#FF6400] ">
            {t("feature2Title")}
          </h2>
          <h3 className="text-3xl md:text-5xl font-semibold mb-10 mt-8">
            <p className="md:text-right">{t("feature2Subtitle1")}</p>
            <p className="md:text-right">{t("feature2Subtitle2")}</p>
          </h3>
          <div className="text-xl md:text-2xl md:text-right">
            <p className="mb-1">{t("feature2Text1")}</p>
            <p className="mb-1">{t("feature2Text2")}</p>
            <p className="mb-1">{t("feature2Text3")}</p>
            <p>{t("feature2Text4")}</p>
          </div>
        </div>
        <div className="relative w-4/5 md:w-3/5 bottom-10 md:bottom-24 md:pr-40">
          <motion.section
            ref={ref}
            animate={controls}
            initial="hidden"
            variants={{
              visible: { opacity: 1, scale: 1, y: 0 },
              hidden: { opacity: 0, scale: 0.95, y: 50 },
            }}
            transition={{ duration: 0.6 }}
            className="motion-section"
          >
            <img
              src={image2}
              alt={t("feature2Title")}
              className="rounded-md shadow-lg transition-opacity duration-500"
            />
          </motion.section>
        </div>
      </div>
      {/* Feature 3 */}
      <div className="flex flex-col-reverse justify-evenly md:flex-row text-center items-center space-y-44 md:space-y-0 animate-fadeIn">
        <div className="relative w-4/5 md:w-3/5 bottom-24 md:bottom-40 md:pl-40">
          <motion.section
            ref={ref2}
            animate={controls2}
            initial="hidden"
            variants={{
              visible: { opacity: 1, scale: 1, y: 0 },
              hidden: { opacity: 0, scale: 0.95, y: 50 },
            }}
            transition={{ duration: 0.6 }}
            className="motion-section"
          >
            <img
              src={image3}
              alt={t("feature3Title")}
              className="rounded-md shadow-lg transition-opacity duration-500"
            />
          </motion.section>
        </div>
        <div className="relative md:w-2/4 text-center md:text-start bottom-36 pl-0 md:pl-16">
          <h2 className="text-5xl md:text-7xl font-bold text-[#FF6400] mb-4">
            {t("feature3Title")}
          </h2>
          <h3 className="text-3xl md:text-5xl font-semibold mb-10 mt-8">
            <p>{t("feature3Subtitle1")}</p>
            <p>{t("feature3Subtitle2")}</p>
            <p>
              {t("feature3Subtitle3")}{" "}
              <span className="inline md:block">{t("feature3Subtitle4")}</span>
            </p>
          </h3>
          <div className="text-xl md:text-2xl">
            <p className="mb-1">
              {t("feature3Text1")}{" "}
              <span className="inline md:block">{t("feature3Text2")}</span>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeaturesSection;
