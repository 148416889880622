import React, { useState, useEffect } from "react";
import axios from "axios";

const EditForm = ({ formData, onSave, onCancel, token }) => {
  const initialFormData = {
    id: null,
    name: "",
    sets: "",
    // default_reps: "",
    // default_weights: "",
    muscle: "",
    description: "",
    images: [], // Updated to match the server's expected key
    drive_video_link: "",
    // videos: [], // Contains video objects
  };

  const [formValues, setFormValues] = useState(initialFormData);
  const [previewImages, setPreviewImages] = useState([]);
  // const [previewVideos, setPreviewVideos] = useState([]);
  const [loading, setLoading] = useState(false);

  //   const [loading, setLoading] = useState(false);
  //   setLoading(true);
  // setLoading(false);
  // disabled={loading}
  //         {loading ? "Loading..." : "Save"}

  useEffect(() => {
    if (!formData) {
      console.warn("No formData provided!");
      return;
    }

    console.log("formData received:", formData);

    setFormValues({
      id: formData.id || null,
      name: formData.name || "",
      sets: formData.sets || "",
      // default_reps: formData.default_reps || "",
      // default_weights: formData.default_weights || "",
      muscle: formData.muscle || "",
      description: formData.description || "",
      drive_video_link: formData.drive_video_link || "",
      images: formData.photos || [], // Use 'images' to align with the API
      // videos: formData.videos || [],
    });

    const imagePreviews = (formData.photos || []).map((photo) => ({
      url: photo.url,
      preview: true,
    }));
    console.log("Image previews:", imagePreviews);
    setPreviewImages(imagePreviews);

    // const videoPreviews = (formData.videos || []).map((video) => ({
    //   url: video.url,
    //   preview: true,
    // }));
    // console.log("Video previews:", videoPreviews);
    // setPreviewVideos(videoPreviews);
  }, [formData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({ ...prevValues, [name]: value }));
  };

  const handleFileChange = (e, type) => {
    const files = Array.from(e.target.files);

    // Update formValues with new files
    setFormValues((prevValues) => ({
      ...prevValues,
      [type]: files,
    }));

    // Generate previews for newly added files
    const previews = files.map((file) => ({
      url: URL.createObjectURL(file),
      preview: false,
    }));

    if (type === "images") {
      setPreviewImages(previews);
    }
    // else if (type === "videos") {
    //   setPreviewVideos(previews);
    // }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = new FormData();
    data.append("id", formValues.id);
    data.append("name", formValues.name);
    data.append("sets", formValues.sets);
    // data.append("default_reps", formValues.default_reps);
    // data.append("default_weights", formValues.default_weights);
    data.append("muscle", formValues.muscle);
    data.append("description", formValues.description);
    data.append("drive_video_link", formValues.drive_video_link);

    formValues.images.forEach((image, index) => {
      if (image instanceof File) {
        data.append(`images[${index}]`, image);
      }
    });

    // formValues.videos.forEach((video, index) => {
    //   if (video instanceof File) {
    //     data.append(`videos[${index}]`, video);
    //   }
    // });

    try {
      setLoading(true);

      const response = await axios.post(
        `https://api.drmicheladib.com/api/v1/admin/exercises/${formValues.id}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      onSave(response.data);
    } catch (error) {
      console.error("Error updating exercise:", error);
    }
    setLoading(false);
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div className="bg-[#252525] p-10 rounded-2xl shadow-lg shadow-slate-600 max-w-3xl w-full mt-10 max-h-[95vh] overflow-auto">
        <form className="mt-5 space-y-6" onSubmit={handleSubmit}>
          <div className="rounded-md shadow-sm space-y-4 ">
            <div>
              <h2 className="text-white text-start text-2xl mb-2 font-bold">
                Name
              </h2>
              <input
                id="name"
                name="name"
                type="text"
                required
                className="w-full px-4 py-2 bg-[#202023] border-gray-500 rounded-lg text-white transition duration-300 ease-in-out transform border-4 border-transparent focus:outline-none focus:border-orange-500"
                placeholder="Name"
                value={formValues.name}
                onChange={handleInputChange}
              />
            </div>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <h2 className="text-white text-start text-2xl mb-2 font-bold">
                  Sets
                </h2>
                <input
                  id="sets"
                  name="sets"
                  type="number"
                  required
                  className="w-full px-4 py-2 bg-[#202023] border-gray-500 rounded-lg text-white transition duration-300 ease-in-out transform border-4 border-transparent focus:outline-none focus:border-orange-500"
                  placeholder="Sets"
                  value={formValues.sets}
                  onChange={handleInputChange}
                />
              </div>

              {/* <div>
                <h2 className="text-white text-start text-2xl mb-2 font-bold">
                  Default Reps
                </h2>
                <input
                  id="default_reps"
                  name="default_reps"
                  type="number"
                  required
                  className="w-full px-4 py-2 bg-[#202023] border-gray-500 rounded-lg text-white transition duration-300 ease-in-out transform border-4 border-transparent focus:outline-none focus:border-orange-500"
                  placeholder="Default Reps"
                  value={formValues.default_reps}
                  onChange={handleInputChange}
                />
              </div>

              <div>
                <h2 className="text-white text-start text-2xl mb-2 font-bold">
                  Default Weights
                </h2>
                <input
                  id="default_weights"
                  name="default_weights"
                  type="number"
                  required
                  className="w-full px-4 py-2 bg-[#202023] border-gray-500 rounded-lg text-white transition duration-300 ease-in-out transform border-4 border-transparent focus:outline-none focus:border-orange-500"
                  placeholder="Default Weights"
                  value={formValues.default_weights}
                  onChange={handleInputChange}
                />
              </div> */}

              <div>
                <h2 className="text-white text-start text-2xl mb-2 font-bold">
                  Exercise Type
                </h2>
                <input
                  id="muscle"
                  name="muscle"
                  type="text"
                  required
                  className="w-full px-4 py-2 bg-[#202023] border-gray-500 rounded-lg text-white transition duration-300 ease-in-out transform border-4 border-transparent focus:outline-none focus:border-orange-500"
                  placeholder="Exercise Type"
                  value={formValues.muscle}
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <div>
              <h2 className="text-white text-start text-2xl mb-2 font-bold">
                Description
              </h2>
              <textarea
                id="description"
                name="description"
                value={formValues.description}
                onChange={handleInputChange}
                rows="4"
                className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
              />
            </div>

            <div className="mb-6">
              <h2 className="text-white text-start text-2xl mb-2 font-bold">
                Photos
              </h2>
              <input
                id="images"
                name="images"
                type="file"
                multiple
                accept="image/*"
                className="w-full px-4 py-2 bg-[#202023] border-gray-500 rounded-lg text-white transition duration-300 ease-in-out transform border-4 border-transparent focus:outline-none focus:border-orange-500"
                onChange={(e) => handleFileChange(e, "images")}
              />
              <div className="mt-4 grid grid-cols-3 gap-2">
                {previewImages.map((image, index) => (
                  <div key={index} className="relative">
                    <img
                      src={image.url}
                      alt={`Preview ${index}`}
                      className="w-24 h-24 object-cover rounded"
                    />
                  </div>
                ))}
              </div>
            </div>
            <div>
              <h2 className="text-white text-start text-2xl mb-2 font-bold">
                Drive Video Link
              </h2>
              <input
                id="drive_video_link"
                name="drive_video_link"
                type="text"
                required
                className="w-full px-4 py-2 bg-[#202023] border-gray-500 rounded-lg text-white transition duration-300 ease-in-out transform border-4 border-transparent focus:outline-none focus:border-orange-500"
                placeholder="Name"
                value={formValues.drive_video_link}
                onChange={handleInputChange}
              />
            </div>
            {/* 
            <div className="mb-6">
              <h2 className="text-white text-start text-2xl mb-2 font-bold">
                Videos
              </h2>
              <input
                id="videos"
                name="videos"
                type="file"
                multiple
                accept="video/*"
                className="w-full px-4 py-2 bg-[#202023] border-gray-500 rounded-lg text-white transition duration-300 ease-in-out transform border-4 border-transparent focus:outline-none focus:border-orange-500"
                onChange={(e) => handleFileChange(e, "videos")}
              />
              <div className="mt-4 grid grid-cols-3 gap-2">
                {previewVideos.map((video, index) => (
                  <div key={index} className="relative">
                    <video
                      src={video.url}
                      controls
                      className="w-24 h-24 object-cover rounded"
                    />
                  </div>
                ))}
              </div>
            </div> */}
          </div>
          <div className="flex justify-end space-x-4">
            <button
              type="button"
              className="bg-gray-500 text-white py-2 px-4 rounded-lg shadow hover:bg-gray-600 focus:outline-none"
              onClick={onCancel}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="bg-green-500 text-white py-2 px-4 rounded-lg shadow hover:bg-green-600 focus:outline-none"
              disabled={loading}
            >
              {loading ? "Loading..." : "Edit"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditForm;
