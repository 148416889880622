import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import ar from "./ar/ar.json";
import en from "./en/en.json";

i18next.use(initReactI18next).init({
  resources: {
    en: {
      translation: en,
    },
    ar: {
      translation: ar,
    },
  },
  lng: localStorage.getItem("lng") || "en",
  fallbackLng: "en", // Fallback language if translation is missing
  interpolation: {
    escapeValue: false, // React already escapes values, XSS prevention
  },
});

export default i18next;
