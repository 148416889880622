import React from "react";
import { useNavigate } from "react-router-dom";

const Logout = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("authenticatedUser");
    localStorage.removeItem("authenticatedToken");

    navigate("/landing");
  };

  const handleCancel = () => {
    navigate(-1);
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div className="bg-white pt-10 pb-10 px-10 rounded-2xl shadow-lg shadow-slate-600 max-w-lg w-full">
        <h2 className="text-black text-center text-2xl mb-6 font-bold">
          Are you sure you want to logout?
        </h2>
        <div className="flex justify-center space-x-4">
          <button
            type="button"
            className="bg-red-500 text-white py-2 px-4 rounded-lg shadow hover:bg-red-600 focus:outline-none"
            onClick={handleLogout}
          >
            Logout
          </button>
          <button
            type="button"
            className="bg-gray-500 text-white py-2 px-4 rounded-lg shadow hover:bg-gray-600 focus:outline-none"
            onClick={handleCancel}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default Logout;
