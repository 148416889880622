import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Loader from "../../../../components/Loader/Loader";
import { IoCheckbox } from "react-icons/io5";
import { ImCheckboxUnchecked } from "react-icons/im";
import WorkoutDetails from "./WorkoutDetails";
import AddWorkouts from "./AddWorkouts";
import { FaPlusCircle, FaToggleOff, FaToggleOn } from "react-icons/fa";
import AddMeals from "./AddMeals";
import nullImage from "../../../../assets/images/nullimage.png";

const UserDetails = () => {
  const { id } = useParams();
  const [user, setUser] = useState({});
  const [userMeal, setUserMeal] = useState([]);
  const [userWorkout, setUserWorkout] = useState([]);
  const [loading, setLoading] = useState(true); // Added loading state
  const [showWorkoutsSchedule, setShowWorkoutsSchedule] = useState(false);
  const [showMealsSchedule, setShowMealsSchedule] = useState(false);
  const [plans, setPlans] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  // const [activation, setActivation] = useState({ plan_id: "", user_id: id });

  const token = localStorage.getItem("authenticatedToken");
  const [fullscreenImage, setFullscreenImage] = useState(null);
  const [fullscreenImage2, setFullscreenImage2] = useState(null);
  const [shouldFetch, setShouldFetch] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      await Promise.all([
        fetchUser(),
        fetchUserMeal(),
        fetchUserWorkout(),
        fetchPlans(),
      ]);
      setLoading(false); // Set loading to false after data is fetched
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (shouldFetch) {
      fetchUser();
      fetchUserMeal();
      fetchUserWorkout();
      setShouldFetch(false); // Reset the flag
    }
  }, [shouldFetch]);

  const fetchUser = async () => {
    try {
      const response = await axios.get(
        `https://api.drmicheladib.com/api/v1/admin/users/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setUser(response.data.data);
    } catch (error) {
      console.error("Error fetching user:", error);
    }
  };

  const fetchUserMeal = async () => {
    try {
      const response = await axios.get(
        `https://api.drmicheladib.com/api/v1/admin/users/${id}/meals?paginate=1000`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setUserMeal(response.data.data);
    } catch (error) {
      console.error("Error fetching user meals:", error);
    }
  };

  const fetchUserWorkout = async () => {
    try {
      const response = await axios.get(
        `https://api.drmicheladib.com/api/v1/admin/users/${id}/workouts?paginate=1000`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setUserWorkout(response.data.data);
    } catch (error) {
      console.error("Error fetching user meals:", error);
    }
  };

  const fetchPlans = async () => {
    try {
      const response = await axios.get(
        "https://api.drmicheladib.com/api/v1/admin/plans?paginate=1000",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setPlans(response.data.data);
    } catch (error) {
      console.error("Error fetching plans:", error);
    }
  };

  const toggleUserActivation = async () => {
    try {
      const updatedActivation = {
        user_id: id,
      };

      if (user.has_active_subscription) {
        // Deactivate the user
        const response = await axios.post(
          `https://api.drmicheladib.com/api/v1/admin/plans/deactivate/user`,
          updatedActivation,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setShouldFetch(true);
      } else {
        setShowModal(true);
      }
    } catch (error) {
      console.error("Error toggling user activation:", error);
    }
  };

  const handlePlanSelect = (planId) => {
    setSelectedPlan(planId);
  };

  const handleActivationSubmit = async () => {
    if (!selectedPlan) {
      alert("Please select a plan.");
      return;
    }

    try {
      const updatedActivation = {
        user_id: id,
        plan_id: selectedPlan,
      };
      const response = await axios.post(
        `https://api.drmicheladib.com/api/v1/admin/plans/subscribe/user`,
        updatedActivation,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setShowModal(false);
      setShouldFetch(true);
    } catch (error) {
      console.error("Error toggling user activation:", error);
    }
  };

  const handleImageClick = (imageUrl) => {
    setFullscreenImage(imageUrl);
  };

  const handleImageClick2 = (imageUrl) => {
    setFullscreenImage2(imageUrl);
  };

  const closeFullscreen = () => {
    setFullscreenImage(null);
    setFullscreenImage2(null);
  };

  // Format date to a more readable format
  const formatDate = (dateString) => {
    const options = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  if (loading) {
    return <Loader />; // Show loader while data is being fetched
  }
  console.log(user);
  return (
    <div className="p-8 bg-gray-100 min-h-screen overflow-hidden">
      <div className="flex items-center mb-8">
        <div>
          <img
            className="w-24 mr-4"
            src={user?.profile_image[0]?.url || nullImage}
          />
        </div>
        <div>
          <h2 className="text-3xl font-bold ">{user?.name || "N/A"}</h2>
          <p className="text-gray-600 ">{user?.email || "N/A"}</p>
        </div>
      </div>
      <div className="flex md:space-x-8 md:space-y-0 space-y-4 pb-4 flex-col md:flex-row justify-around">
        <button
          type="button"
          className={`h-12 inline-flex items-center px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white ${
            user.has_active_subscription ? "bg-red-600" : "bg-green-600"
          } hover:bg-${
            user.has_active_subscription ? "red" : "green"
          }-700 focus:outline-none`}
          onClick={toggleUserActivation}
        >
          {user.has_active_subscription ? (
            <>
              <FaToggleOff className="mr-2" />
              Deactivate
            </>
          ) : (
            <>
              <FaToggleOn className="mr-2" />
              Activate
            </>
          )}
        </button>
        <button
          type="button"
          className="h-12 inline-flex items-center px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none"
          onClick={() => setShowWorkoutsSchedule(true)}
        >
          <FaPlusCircle className="mr-2" />
          Workouts Schedule
        </button>
        <button
          type="button"
          className="h-12 inline-flex items-center px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none"
          onClick={() => setShowMealsSchedule(true)}
        >
          <FaPlusCircle className="mr-2" />
          Meals Schedule
        </button>
      </div>
      {showWorkoutsSchedule && (
        <AddWorkouts
          setShouldFetch={setShouldFetch}
          CloseWorkoutsSchedule={() => setShowWorkoutsSchedule(false)}
        />
      )}

      {showMealsSchedule && (
        <AddMeals
          setShouldFetch={setShouldFetch}
          CloseMealsSchedule={() => setShowMealsSchedule(false)}
        />
      )}
      {showModal && (
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div
              className="fixed inset-0 transition-opacity"
              aria-hidden="true"
            >
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>

            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div>
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  Select a Plan
                </h3>
                <div className="mt-2">
                  {plans && plans.length > 0 ? (
                    plans.map((plan) => (
                      <button
                        key={plan.id}
                        onClick={() => handlePlanSelect(plan.id)}
                        className={`block w-full text-left p-4 my-2 border ${
                          selectedPlan === plan.id
                            ? "border-blue-500"
                            : "border-gray-300"
                        } rounded-lg cursor-pointer`}
                      >
                        {plan.name} - {plan.duration} Month(s)
                      </button>
                    ))
                  ) : (
                    <p>No plans available</p>
                  )}
                </div>
              </div>
              <div className="mt-5 sm:mt-6">
                <button
                  type="button"
                  className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none sm:text-sm"
                  onClick={handleActivationSubmit}
                >
                  Activate Plan
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      <section className="mb-8">
        <h3 className="text-2xl font-semibold mb-4">Personal Details:</h3>
        <div className="grid grid-cols-1 md:grid-cols-4 gap-4 md:gap-8">
          <div>
            <label className="block text-sm font-semibold text-gray-600 mb-1">
              Sex:
            </label>
            <input
              value={user?.sex || "N/A"}
              disabled
              className="input-field w-full"
            />
          </div>
          <div>
            <label className="block text-sm font-semibold text-gray-600 mb-1">
              Phone:
            </label>
            <input
              value={user?.phone || "N/A"}
              disabled
              className="input-field w-full"
            />
          </div>
          <div>
            <label className="block text-sm font-semibold text-gray-600 mb-1">
              Instagram Account:
            </label>
            <input
              value={user?.instagram_account || "N/A"}
              disabled
              className="input-field w-full"
            />
          </div>
          <div>
            <label className="block text-sm font-semibold text-gray-600 mb-1">
              Birth Date:
            </label>
            <input
              value={user?.birth_date || "N/A"}
              disabled
              className="input-field w-full"
            />
          </div>
          <div>
            <label className="block text-sm font-semibold text-gray-600 mb-1">
              Country:
            </label>
            <input
              value={user?.country || "N/A"}
              disabled
              className="input-field w-full"
            />
          </div>
          <div>
            <label className="block text-sm font-semibold text-gray-600 mb-1">
              City:
            </label>
            <input
              value={user?.city || "N/A"}
              disabled
              className="input-field w-full"
            />
          </div>
          <div>
            <label className="block text-sm font-semibold text-gray-600 mb-1">
              Location:
            </label>
            <input
              value={user?.location || "N/A"}
              disabled
              className="input-field w-full"
            />
          </div>
          <div>
            <label className="block text-sm font-semibold text-gray-600 mb-1">
              Goal:
            </label>
            <input
              value={user?.goal || "N/A"}
              disabled
              className="input-field w-full"
            />
          </div>
          <div>
            <label className="block text-sm font-semibold text-gray-600 mb-1">
              Weight:
            </label>
            <input
              value={user?.weight || "N/A"}
              disabled
              className="input-field w-full"
            />
          </div>
          <div>
            <label className="block text-sm font-semibold text-gray-600 mb-1">
              Height:
            </label>
            <input
              value={user?.height || "N/A"}
              disabled
              className="input-field w-full"
            />
          </div>
          <div>
            <label className="block text-sm font-semibold text-gray-600 mb-1">
              Previous Diet Status:
            </label>
            <input
              value={user?.previous_diet_status || "N/A"}
              disabled
              className="input-field w-full"
            />
          </div>
          <div>
            <label className="block text-sm font-semibold text-gray-600 mb-1">
              Has Allergies:
            </label>
            <input
              value={user?.has_allergies || "N/A"}
              disabled
              className="input-field w-full"
            />
          </div>
          <div>
            <label className="block text-sm font-semibold text-gray-600 mb-1">
              Allergies Details:
            </label>
            <input
              value={user?.allergies_details || "N/A"}
              disabled
              className="input-field w-full"
            />
          </div>
          <div>
            <label className="block text-sm font-semibold text-gray-600 mb-1">
              Smoking Status:
            </label>
            <input
              value={user?.smoking_status || "N/A"}
              disabled
              className="input-field w-full"
            />
          </div>
          <div>
            <label className="block text-sm font-semibold text-gray-600 mb-1">
              Alcohol Status:
            </label>
            <input
              value={user?.alcohol_status || "N/A"}
              disabled
              className="input-field w-full"
            />
          </div>
          <div>
            <label className="block text-sm font-semibold text-gray-600 mb-1">
              Sports Practiced:
            </label>
            <input
              value={user?.sports_practiced || "N/A"}
              disabled
              className="input-field w-full"
            />
          </div>
          <div>
            <label className="block text-sm font-semibold text-gray-600 mb-1">
              Uses Protein Supplement:
            </label>
            <input
              value={user?.uses_protein_supplement || "N/A"}
              disabled
              className="input-field w-full"
            />
          </div>
          <div>
            <label className="block text-sm font-semibold text-gray-600 mb-1">
              Unwanted Carbohydrate:
            </label>
            <input
              value={user?.unwanted_carbohydrate || "N/A"}
              disabled
              className="input-field w-full"
            />
          </div>
          <div>
            <label className="block text-sm font-semibold text-gray-600 mb-1">
              Unwanted Protein:
            </label>
            <input
              value={user?.unwanted_protein || "N/A"}
              disabled
              className="input-field w-full"
            />
          </div>
          <div>
            <label className="block text-sm font-semibold text-gray-600 mb-1">
              Unwanted Fats:
            </label>
            <input
              value={user?.unwanted_fats || "N/A"}
              disabled
              className="input-field w-full"
            />
          </div>
          <div>
            <label className="block text-sm font-semibold text-gray-600 mb-1">
              Registration Complete:
            </label>
            <input
              value={user?.registration_complete || "N/A"}
              disabled
              className="input-field w-full"
            />
          </div>
          <div>
            <label className="block text-sm font-semibold text-gray-600 mb-1">
              Onboarding Current Step:
            </label>
            <input
              value={user?.onboarding_current_step || "N/A"}
              disabled
              className="input-field w-full"
            />
          </div>
          <div>
            <label className="block text-sm font-semibold text-gray-600 mb-1">
              Has Active Subscription:
            </label>
            <input
              value={user?.has_active_subscription ? "yes" : "no"}
              disabled
              className="input-field w-full"
            />
          </div>
          <div>
            <label className="block text-sm font-semibold text-gray-600 mb-1">
              Requested Plan:
            </label>
            <input
              value={user?.plan_subscriptions?.name || "N/A"}
              disabled
              className="input-field w-full"
            />
          </div>
          <div>
            <label className="block text-sm font-semibold text-gray-600 mb-1">
              Starts At:
            </label>
            <input
              value={
                user?.plan_subscriptions?.starts_at
                  ? formatDate(user?.plan_subscriptions?.starts_at)
                  : "N/A"
              }
              disabled
              className="input-field w-full"
            />
          </div>
          <div>
            <label className="block text-sm font-semibold text-gray-600 mb-1">
              Ends At:
            </label>
            <input
              value={
                user?.plan_subscriptions?.ends_at
                  ? formatDate(user?.plan_subscriptions?.ends_at)
                  : "N/A"
              }
              disabled
              className="input-field w-full"
            />
          </div>
        </div>
        <div className="p-8 bg-gray-100">
          <h1 className="text-3xl font-semibold">User Images</h1>
          {/* Image Gallery */}
          <div className="image-gallery">
            {user.main_images > 0
              ? user?.main_images?.map((image) => (
                  <img
                    key={image.id}
                    className="w-24 inline m-5 cursor-pointer"
                    src={image.url}
                    alt={image.name}
                    onClick={() => handleImageClick(image.url)}
                  />
                ))
              : "No Images"}
          </div>

          {/* Fullscreen Image Modal */}
          {fullscreenImage && (
            <div
              className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50"
              onClick={closeFullscreen}
            >
              <img
                className="max-w-full max-h-full"
                src={fullscreenImage}
                alt="Fullscreen View"
              />
            </div>
          )}
        </div>

        <div className="p-8 bg-gray-100">
          <h1 className="text-3xl font-semibold">Payments Images</h1>
          {/* Image Gallery */}
          <div className="image-gallery md:flex md:space-x-5">
            {user.payment_receipt > 0
              ? user?.payment_receipt?.map((image, index) => (
                  <div key={index}>
                    <img
                      key={image.id}
                      className="w-24 m-5  cursor-pointer"
                      src={image.url}
                      alt={image.name}
                      onClick={() => handleImageClick2(image.url)}
                    />

                    <p>
                      {image?.created_at
                        ? formatDate(image?.created_at)
                        : "N/A"}
                    </p>
                  </div>
                ))
              : "No Images"}
          </div>

          {/* Fullscreen Image Modal */}
          {fullscreenImage2 && (
            <div
              className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50"
              onClick={closeFullscreen}
            >
              <img
                className="max-w-full max-h-full"
                src={fullscreenImage2}
                alt="Fullscreen View"
              />
            </div>
          )}
        </div>
      </section>
      <div className="min-w-full py-2 align-middle inline-block sm:px-6 lg:px-8">
        <p className="font-semibold text-3xl mb-4">Meals Schedule</p>
        <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200 table-auto">
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Name
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Meal Type
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Meal Date
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Checked
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {userMeal.length > 0 ? (
                userMeal.map((meal, index) => (
                  <tr key={index}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      {meal.title}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {meal.meal_type}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {meal.pivot.meal_date}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {meal.pivot.consumed_at == null ? (
                        <ImCheckboxUnchecked />
                      ) : (
                        <IoCheckbox />
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan="4"
                    className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center"
                  >
                    No meals found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      <div className="min-w-full py-2 align-middle inline-block sm:px-6 lg:px-8 mt-10">
        <p className="font-semibold text-3xl mb-4"> Workouts Schedule</p>
        <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50 text-center">
              <tr>
                <th
                  scope="col"
                  className="px-6 py-3  text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Name
                </th>

                {/* <th
                  scope="col"
                  className="px-6 py-3  text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Workout Date
                </th> */}
                <th
                  scope="col"
                  className="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Exercises
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {userWorkout.length > 0 ? (
                userWorkout.map((workout, index) => (
                  <tr key={index}>
                    <td className="px-6 py-4 text-center text-sm font-medium text-gray-900">
                      {workout.title}
                    </td>

                    {/* <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {workout.pivot.workout_schedule_date}
                    </td> */}

                    <td>
                      <WorkoutDetails workout={workout} />
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan="4"
                    className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center"
                  >
                    No workout found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default UserDetails;
