import axios from "axios";
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
// import Loader from "../../../components/Loader/Loader";

const options = {
  chart: {
    fontFamily: "Satoshi, sans-serif",
    type: "donut",
  },
  colors: ["#3C50E0", "#8FD0EF", "#0FAD90", "#6577F3"],
  labels: ["1 month plan", "4 months plan", "not subscribed"],
  legend: {
    show: false,
    position: "bottom",
  },
  plotOptions: {
    pie: {
      donut: {
        size: "65%",
        background: "transparent",
      },
    },
  },
  dataLabels: {
    enabled: false,
  },
  responsive: [
    {
      breakpoint: 2600,
      options: {
        chart: {
          width: 380,
        },
      },
    },
    {
      breakpoint: 640,
      options: {
        chart: {
          width: 200,
        },
      },
    },
  ],
};

const ChartCircle = () => {
  const [chartData, setChartData] = useState([0, 0, 0]);
  // const [loading, setLoading] = useState(true);

  const token = localStorage.getItem("authenticatedToken");

  useEffect(() => {
    const fetchStatus = async () => {
      try {
        const response = await axios.get(
          "https://api.drmicheladib.com/api/v1/admin/reports/subscription",
          {
            headers: {
              Authorization: `Bearer ${token}`,
              Accept: "application/json",
            },
          }
        );
        const data = response.data.data;
        setChartData([
          data[0].percentage,
          data[1].percentage,
          data[2].percentage,
        ]);
        // setLoading(false);
      } catch (error) {
        console.error("Error fetching user:", error);
        // setLoading(false);
      }
    };

    fetchStatus();
  }, [token]);

  const handleReset = () => {
    setChartData([65, 34, 12]);
  };

  // if (loading) {
  //   return <Loader />;
  // }

  return (
    <div className="sm:px-7.5 col-span-12 rounded-sm border border-stroke bg-white px-5 pb-5 pt-7.5 shadow-default xl:col-span-5">
      <div className="mb-3 justify-between gap-4 sm:flex">
        <div>
          <h5 className="text-xl font-semibold text-black">
            Subscription Analytics
          </h5>
        </div>
        <div></div>
      </div>

      <div className="mb-2">
        <div id="chartThree" className="mx-auto flex justify-center">
          <ReactApexChart options={options} series={chartData} type="donut" />
        </div>
      </div>

      <div className="-mx-8 flex flex-wrap items-center justify-center gap-y-3">
        <div className="sm:w-1/2 w-full px-8">
          <div className="flex w-full items-center">
            <span className="mr-2 block h-3 w-full max-w-3 rounded-full bg-[#3C50E0]"></span>
            <p className="flex w-full justify-between text-sm font-medium text-black">
              <span>1 month plan </span>
              <span> {chartData[0]}% </span>
            </p>
          </div>
        </div>
        <div className="sm:w-1/2 w-full px-8">
          <div className="flex w-full items-center">
            <span className="mr-2 block h-3 w-full max-w-3 rounded-full bg-[#8FD0EF]"></span>
            <p className="flex w-full justify-between text-sm font-medium text-black">
              <span> 4 months plan </span>
              <span> {chartData[1]}% </span>
            </p>
          </div>
        </div>
        <div className="sm:w-1/2 w-full px-8">
          <div className="flex w-full items-center">
            <span className="mr-2 block h-3 w-full max-w-3 rounded-full bg-[#0FAD90]"></span>
            <p className="flex w-full justify-between text-sm font-medium text-black">
              <span> not subscribed </span>
              <span> {chartData[2]}% </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChartCircle;
