import React from "react";

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      onPageChange(page);
    }
  };

  // Define the number of page buttons to show
  const pageRange = 5;

  // Calculate the start and end page numbers to display
  let startPage = Math.max(1, currentPage - Math.floor(pageRange / 2));
  let endPage = Math.min(totalPages, startPage + pageRange - 1);

  // Adjust start and end pages if they exceed the boundaries
  if (endPage - startPage < pageRange - 1) {
    startPage = Math.max(1, endPage - pageRange + 1);
  }

  return (
    <div className="mt-4 flex justify-center">
      <button
        onClick={() => handlePageChange(currentPage - 1)}
        disabled={currentPage === 1}
        className="px-4 py-2 mx-2 bg-gray-300 text-gray-700 rounded hover:bg-green-300 disabled:opacity-50 disabled:-z-10"
      >
        Previous
      </button>
      {startPage > 1 && (
        <>
          <button
            onClick={() => handlePageChange(1)}
            className="px-4 py-2 mx-2 bg-gray-300 text-gray-700 rounded hover:bg-green-300"
          >
            1
          </button>
          {startPage > 2 && (
            <span className="px-4 py-2 mx-2 text-gray-700">...</span>
          )}
        </>
      )}
      {[...Array(endPage - startPage + 1)].map((_, index) => (
        <button
          key={index + startPage}
          onClick={() => handlePageChange(index + startPage)}
          className={`px-4 py-2 mx-2 ${
            currentPage === index + startPage
              ? "bg-green-500 text-white"
              : "bg-gray-300 text-gray-700"
          } rounded hover:bg-green-300`}
        >
          {index + startPage}
        </button>
      ))}
      {endPage < totalPages && (
        <>
          {endPage < totalPages - 1 && (
            <span className="px-4 py-2 mx-2 text-gray-700">...</span>
          )}
          <button
            onClick={() => handlePageChange(totalPages)}
            className="px-4 py-2 mx-2 bg-gray-300 text-gray-700 rounded hover:bg-green-300"
          >
            {totalPages}
          </button>
        </>
      )}
      <button
        onClick={() => handlePageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
        className="px-4 py-2 mx-2 bg-gray-300 text-gray-700 rounded hover:bg-green-300 disabled:opacity-50 disabled:-z-10"
      >
        Next
      </button>
    </div>
  );
};

export default Pagination;
