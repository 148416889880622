import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";

const AddWorkouts = ({ CloseWorkoutsSchedule, setShouldFetch }) => {
  const { id } = useParams();
  const initialFormData = {
    user_ids: [],
    workout_ids: [],
    workout_count: "",
  };

  const [formValues, setFormValues] = useState(initialFormData);
  const [workouts, setWorkouts] = useState([]);
  const [workoutSearch, setWorkoutSearch] = useState(""); // State for workout search
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("authenticatedToken");

  useEffect(() => {
    fetchWorkouts();
  }, []);

  const fetchWorkouts = async () => {
    try {
      const response = await axios.get(
        "https://api.drmicheladib.com/api/v1/admin/workouts?paginate=10000",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setWorkouts(response.data.data);
    } catch (error) {
      console.error("Error fetching workouts:", error);
    }
  };

  const handleWorkoutChange = (id) => {
    setFormValues((prevValues) => {
      const workout_ids = prevValues.workout_ids.includes(id)
        ? prevValues.workout_ids.filter((workout_id) => workout_id !== id)
        : [...prevValues.workout_ids, id];
      return { ...prevValues, workout_ids };
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validation: Ensure at least one user and one workout are selected

    if (formValues.workout_ids.length === 0) {
      alert("Please select at least one workout.");
      return;
    }
    if (formValues.workout_count === "") {
      alert("Please enter a workout count.");
      return;
    }

    const payload = {
      user_ids: [id],
      workout_ids: formValues.workout_ids,
      workout_count: parseInt(formValues.workout_count, 10),
    };

    // console.log("Payload:", payload);

    try {
      setLoading(true);

      const response = await axios.post(
        "https://api.drmicheladib.com/api/v1/admin/workout/bulk-add-users",
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("Workout assigned successfully", response.data);
      CloseWorkoutsSchedule();
      setShouldFetch(true);
    } catch (error) {
      console.error("Error assigning workout:", error);
      alert(`Error: ${error.response ? error.response.data : error.message}`);
    }
    setLoading(false);
  };

  const filteredWorkouts = workouts
    .filter((workout) =>
      workout.title.toLowerCase().includes(workoutSearch.toLowerCase())
    )
    .sort((a, b) => {
      const aSelected = formValues.workout_ids.includes(a.id);
      const bSelected = formValues.workout_ids.includes(b.id);
      return bSelected - aSelected;
    });

  const selectedWorkouts = filteredWorkouts.filter((workout) =>
    formValues.workout_ids.includes(workout.id)
  );
  const unselectedWorkouts = filteredWorkouts.filter(
    (workout) => !formValues.workout_ids.includes(workout.id)
  );

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div className="bg-[#252525] pt-10 pb-24 px-16 rounded-2xl shadow-lg shadow-slate-600 max-w-3xl w-full mt-10">
        <form
          className="mt-5 max-h-[80vh] overflow-y-auto space-y-6"
          onSubmit={handleSubmit}
        >
          <div className="flex space-x-4 mt-6">
            <div className="w-1/2 pr-2">
              <h2 className="text-white text-start text-2xl mb-2 font-bold">
                Workouts
              </h2>
              <input
                type="text"
                placeholder="Search Workouts"
                className="w-full px-4 py-2 mb-3 bg-[#202023] border-gray-500 rounded-lg text-white transition duration-300 ease-in-out transform border-4 border-transparent focus:outline-none focus:border-orange-500"
                value={workoutSearch}
                onChange={(e) => setWorkoutSearch(e.target.value)}
              />
              <div className="max-h-40 overflow-y-auto space-y-2">
                {unselectedWorkouts.map((workout) => (
                  <label
                    key={workout.id}
                    className="flex items-center text-white space-x-3"
                  >
                    <input
                      type="checkbox"
                      checked={formValues.workout_ids.includes(workout.id)}
                      onChange={() => handleWorkoutChange(workout.id)}
                      className="form-checkbox h-5 w-5 text-blue-600"
                    />
                    <span>{workout.title}</span>
                  </label>
                ))}
              </div>
            </div>
            <div className="w-1/2 pl-2">
              <h2 className="text-white text-start text-2xl mb-2 font-bold">
                Selected Workouts ({selectedWorkouts.length})
              </h2>
              <div className="max-h-40 overflow-y-auto space-y-2">
                {selectedWorkouts.map((workout) => (
                  <label
                    key={workout.id}
                    className="flex items-center text-white space-x-3"
                  >
                    <input
                      type="checkbox"
                      checked={formValues.workout_ids.includes(workout.id)}
                      onChange={() => handleWorkoutChange(workout.id)}
                      className="form-checkbox h-5 w-5 text-blue-600"
                    />
                    <span>{workout.title}</span>
                  </label>
                ))}
              </div>
            </div>
          </div>
          <div>
            <h2 className="text-white text-start text-2xl mb-2 font-bold">
              Workout Count
            </h2>
            <input
              id="workout_count"
              name="workout_count"
              type="number"
              required
              className="w-full px-4 py-2 bg-[#202023] border-gray-500 rounded-lg text-white transition duration-300 ease-in-out transform border-4 border-transparent focus:outline-none focus:border-orange-500"
              placeholder="Workout Count"
              value={formValues.workout_count}
              onChange={handleInputChange}
            />
          </div>
          <div className="flex justify-end space-x-4 mt-6">
            <button
              type="button"
              className="bg-gray-500 text-white py-2 px-4 rounded-lg shadow hover:bg-gray-600 focus:outline-none"
              onClick={CloseWorkoutsSchedule}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="bg-green-500 text-white py-2 px-4 rounded-lg shadow hover:bg-green-600 focus:outline-none"
              disabled={loading}
            >
              {loading ? "Loading..." : "Save"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddWorkouts;
