import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FiEye, FiEyeOff } from "react-icons/fi";
import Orangepal from "../assets/images/Orangepal.png";
import axios from "axios";

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [user, setUser] = useState({ email: "", password: "" });
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!user.email || !user.password) {
      setErrorMessage("Please fill in all fields.");
      return;
    }

    setErrorMessage("");
    setIsLoading(true);

    try {
      const response = await axios.post(
        "https://api.drmicheladib.com/api/v1/auth/login",
        user
      );

      // console.log("Login successful!", response.data);
      // console.log("Login successful!", response.data.data.user.user_type);

      localStorage.setItem(
        "authenticatedUser",
        response.data.data.user.user_type
      );

      localStorage.setItem(
        "authenticatedToken",
        response.data.data.authorization.token
      );

      if (response.data.data.user.user_type === "admin") {
        navigate("/dashboard");
      } else {
        navigate("/landing");
      }
    } catch (error) {
      console.error("Error logging in:", error);

      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        setErrorMessage(error.response.data.message);
      } else {
        setErrorMessage("An error occurred. Please try again.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <header className="bg-[#202023] text-white py-6">
        <div className="px-10 md:px-40 text-center items-center">
          <img src={Orangepal} alt="Orangefit Logo" className="w-32 md:w-48" />
        </div>
      </header>

      <div className="min-h-screen flex flex-col items-center justify-center bg-[#151515]">
        <div className="w-full max-w-xl px-8 py-10 bg-[#202023] text-white rounded-2xl shadow-lg">
          <h2 className="text-3xl text-start font-bold mb-6">
            Login to your account
          </h2>
          <form onSubmit={handleSubmit}>
            {errorMessage && (
              <div className="mb-4 text-red-500 text-sm">{errorMessage}</div>
            )}
            <div className="mb-4">
              <label
                className="block text-base font-medium mb-2"
                htmlFor="email"
              >
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                placeholder="Enter your email"
                className="w-full px-4 py-2 bg-[#202023] border-gray-500 rounded-lg text-white transition duration-300 ease-in-out transform border-4 border-transparent focus:outline-none focus:border-orange-500"
                required
                value={user.email}
                onChange={handleInputChange}
              />
            </div>
            <div className="mb-4 relative">
              <label
                className="block text-base font-medium mb-2"
                htmlFor="password"
              >
                Password
              </label>
              <input
                type={showPassword ? "text" : "password"}
                id="password"
                name="password"
                placeholder="Enter your password"
                className="w-full px-4 py-2 bg-[#202023] border-gray-700 rounded-lg text-white transition duration-300 ease-in-out transform border-4 border-transparent focus:outline-none focus:border-orange-500"
                required
                value={user.password}
                onChange={handleInputChange}
              />
              <div
                className="absolute inset-y-0 right-0 flex items-center pr-3  pt-6  cursor-pointer"
                onClick={togglePasswordVisibility}
              >
                {showPassword ? (
                  <FiEyeOff className="text-gray-400" />
                ) : (
                  <FiEye className="text-gray-400" />
                )}
              </div>
            </div>
            <button
              type="submit"
              className="w-full py-3 bg-[#FF6400] text-white font-bold rounded-lg hover:bg-orange-600 transition duration-300"
              disabled={isLoading}
            >
              {isLoading ? "Logging in..." : "Login now"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
