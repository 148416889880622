import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Users from "../pages/Dashboard/Users/Users";
import Meals from "../pages/Dashboard/Meals/Meals";
import Exercises from "../pages/Dashboard/Exercises/Exercises";
import Workouts from "../pages/Dashboard/Workouts/Workouts";
import Routines from "../pages/Dashboard/Routines/Routines";
import PlanManagement from "../pages/Dashboard/Plans/PlanManagement";
import Landing from "../pages/Landing";
import Login from "../pages/Login";
import UserDetails from "../pages/Dashboard/Users/UserDetails/UserDetails";
import Analytics from "../pages/Dashboard/Analytics/Analytics";
import Logout from "../pages/Dashboard/Logout/Logout";
import ProtectedRoute from "./ProtectedRoute";
import Notifications from "../pages/Dashboard/Notifications/Notifications";

const Routers = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/landing" />} />
      <Route path="landing" element={<Landing />} />
      <Route path="login" element={<Login />} />

      <Route
        path="dashboard"
        element={<Navigate to="/dashboard/analytics" />}
      />

      <Route
        path="dashboard/*"
        element={
          <ProtectedRoute>
            <Routes>
              <Route path="analytics" element={<Analytics />} />
              <Route path="users" element={<Users />} />
              <Route path="users/:id" element={<UserDetails />} />
              <Route path="meals" element={<Meals />} />
              <Route path="exercises" element={<Exercises />} />
              <Route path="workouts" element={<Workouts />} />
              <Route path="routines" element={<Routines />} />
              <Route path="notifications" element={<Notifications />} />
              <Route path="plan-management" element={<PlanManagement />} />
              <Route path="logout" element={<Logout />} />
            </Routes>
          </ProtectedRoute>
        }
      />
    </Routes>
  );
};

export default Routers;
