import React, { useEffect, useState } from "react";

import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useTranslation } from "react-i18next";

const StatsSection = () => {
  const { t } = useTranslation();

  const [stats, setStats] = useState({
    clients: 0,
    results: 0,
    workouts: 0,
    recipes: 0,
  });

  // Target stats
  const targetStats = {
    clients: 1200,
    results: 110,
    workouts: 300,
    recipes: 500,
  };

  const controls3 = useAnimation();
  const [ref3, inView3] = useInView({
    threshold: 0.5, // Trigger when 50% of the section is visible
    triggerOnce: true, // Trigger the animation only once
  });

  const handleMouseEnter = () => {
    const duration = 1000; // Duration of the animation in ms
    const steps = 10; // Total animation frames
    const stepTime = duration / steps;
    let intervalId = null;

    intervalId = setInterval(() => {
      setStats((prevStats) => {
        const newStats = { ...prevStats };
        let done = true;

        for (let key in targetStats) {
          if (newStats[key] < targetStats[key]) {
            newStats[key] += Math.ceil(targetStats[key] / steps);
            done = false;
          }
        }

        if (done) {
          clearInterval(intervalId);
          return targetStats;
        }

        return newStats;
      });
    }, stepTime);
  };

  // Use effect to start animation when the section comes into view
  useEffect(() => {
    if (inView3) {
      controls3.start("visible"); // Start the animation
      handleMouseEnter(); // Trigger stats animation
    }
  }, [inView3, controls3]);
  return (
    <section className="bg-[#151515] text-white py-40 -z-20">
      <motion.div
        ref={ref3} // Attach ref to the section to observe its visibility
        initial="hidden"
        animate={controls3}
        variants={{
          hidden: { opacity: 0, y: 50 },
          visible: { opacity: 1, y: 0 },
        }}
        transition={{ duration: 0.5 }}
        className="mx-auto text-center"
      >
        <h2 className="text-[#FF6400] text-3xl md:text-6xl font-bold mb-20">
          {t("Until")}
        </h2>
        <div className="grid md:grid-cols-4 gap-14 py-10">
          <div>
            <p className="text-7xl font-bold">{stats.clients}+</p>
            <p>{t("Happy")}</p>
          </div>
          <div>
            <p className="text-7xl font-bold">{stats.results}%</p>
            <p>{t("Guaranteed")}</p>
          </div>
          <div>
            <p className="text-7xl font-bold">{stats.workouts}+</p>
            <p>{t("Workouts")}</p>
          </div>
          <div>
            <p className="text-7xl font-bold">{stats.recipes}+</p>
            <p>{t("Healthy")}</p>
          </div>
        </div>
      </motion.div>
    </section>
  );
};

export default StatsSection;
