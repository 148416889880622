import React from "react";
import Orangepal from "../../assets/images/Orangepal.png";
import { useTranslation } from "react-i18next";
import whatsapp from "../../assets/images/whatsapp.png";
import instalgram from "../../assets/images/instagram.png";

const Footer = () => {
  const { t, i18n } = useTranslation();
  const textAlignment2 = i18n.language === "ar" ? "text-end" : "text-center";
  return (
    <footer className="bg-[#151515] text-white py-10 mb-28">
      <div className="w-9/12 mx-auto mt-2 mb-20 h-1 bg-gradient-to-r from-transparent via-white to-transparent"></div>

      <div
        className={`mt-4 md:flex flex-row ${textAlignment2} justify-evenly items-center`}
      >
        <div className="flex-col space-y-8 flex justify-between items-center">
          <div className="space-x-2">
            <img
              className="w-1/4 mx-auto"
              src={Orangepal}
              alt="Orangefit Logo"
            />
          </div>
          <div className="flex justify-center space-x-6">
            <a
              href="https://www.instagram.com/drmicheladib?igsh=MzRlODBiNWFlZA=="
              target="_blank"
              rel="noopener noreferrer"
              className="transform transition-transform duration-200 hover:scale-105 hover:shadow-lg rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-500"
            >
              <img
                src={instalgram}
                className="w-14 mx-auto"
                alt="Instagram Logo"
              />
            </a>

            <a href="https://google.com">
              <img
                className="w-16 mx-auto"
                src={whatsapp}
                alt="whatsapp Logo"
              />
            </a>
          </div>
        </div>
      </div>

      <div className="container mx-auto mt-4 flex justify-center space-x-8 mb-4"></div>
      <p className="text-white text-center text-xl">{t("copyright")}</p>
      <p className="text-white text-center font-light tracking-wider mt-3 opacity-90">
        {t("termsAndConditions")}
      </p>
    </footer>
  );
};

export default Footer;
