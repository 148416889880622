import React, { useState, useRef, useEffect } from "react";
import { FaCircleChevronUp, FaCircleChevronDown } from "react-icons/fa6";

const FAQItem = ({ question, answer, isOpen, onClick }) => {
  const [height, setHeight] = useState(0);
  const contentRef = useRef(null);

  useEffect(() => {
    if (isOpen) {
      setHeight(contentRef.current.scrollHeight);
    } else {
      // Delay setting the height to 0 until the transition completes
      setHeight(contentRef.current.scrollHeight); // Set full height first
      setTimeout(() => setHeight(0), 0); // Delay to trigger transition
    }
  }, [isOpen]);

  return (
    <div className="border-t border-orange-500 pt-8 max-w-xl">
      <div
        className="flex justify-between items-center cursor-pointer"
        onClick={onClick}
      >
        <h3 className="text-lg md:text-xl font-semibold">{question}</h3>
        {isOpen ? (
          <FaCircleChevronUp className="flex-none w-6 h-6 mx-4 text-orange-500" />
        ) : (
          <FaCircleChevronDown className="flex-none w-6 h-6 mx-4 text-orange-500" />
        )}
      </div>

      <div
        ref={contentRef}
        style={{ height }}
        className={`text-sm md:text-md mt-2 text-start overflow-hidden transition-all duration-500 ease-in-out`}
      >
        <p className="pt-2">{answer}</p>
      </div>
    </div>
  );
};

export default FAQItem;
